<template>
	<div class="p-grid">
		<div class="p-col-12">
			<h1>Page not found</h1>
		</div>
	</div>
</template>

<script>

export default {
	data() {
		return {}
	}
}
</script>

<style scoped>

</style>
